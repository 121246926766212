import React, { Component } from 'react';
import Layout from '../components/layout';
import Intro from '../components/mask';
import SEO from '../components/seo';
import Carousel from '../components/carousel';
import AboutSection from '../components/aboutSection'
import Card from '../components/card'
import IconBullet from '../components/iconbullet'
import { MDBRow, MDBCol, MDBContainer, MDBIcon, MDBBtn} from 'mdbreact'
import wd_badge from "../images/badge.png"
class App extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Home" keywords={[`gatsby`, `MDBReact`, `react`, `Material Design For Bootstrap`]} />
        <Carousel />
        <main>
          <MDBContainer tag="section"  className="text-center my-5">
            <h2 className="h1-responsive font-weight-bold my-5 text-uppercase">
              CONGRATULATIONS!
            </h2> 
            <h3>You are engaged and about to discover that true love is spending one day getting married and the rest of your life feeling glad that you did!</h3>
            <p className="grey-text mb-md-0 mb-5">
              Time to start planning your wedding and the moment where you will make promises to each other in front of your nearest and dearest.  I’m here to make things stress free and enjoyable by designing something completely unique for you, with you, and about you.
            </p>
            </MDBContainer>
          
          <section id="cardSection">
            
            <MDBRow className="m-0">
              <MDBCol md="6" className="grey-text">
              <MDBContainer className="text-center">
              <MDBIcon far icon="question-circle" size="4x" className="cyan-text mb-2" />
              </MDBContainer>
                <IconBullet far icon="heart">Can’t wait to get married?</IconBullet>
                <IconBullet far icon="clock">Do you lead a busy life and have minimal time to plan a wedding or just like things to happen without any fuss?</IconBullet>
                <IconBullet far icon="star">Is finding the right celebrant one of your top priorities but something you are seriously struggling with?</IconBullet>
                <IconBullet far icon="calendar-alt">Has your wedding come around really quickly and now you are freaking out because you are not willing to compromise on quality or your wedding date and you think all the ‘good’ celebrants are booked out?</IconBullet>
                <IconBullet far icon="comments">Does the thought of a long engagement where all you ever talk about is weddings for months on end make you feel ill?</IconBullet>
              </MDBCol>
              <MDBCol>
                <MDBContainer className="text-center">
                  <MDBIcon icon="check"  size="4x" className="cyan-text mb-2" />
                  <h4 className="text-capitalize">Well I've Got You covered!</h4>
                  <span className="grey-text">
                    <p>Hi I’m Amanda, a warm, engaging, modern and detail oriented marriage celebrant in Adelaide, South Australia.</p>
                    <p>I specialise in bespoke ceremonies and am passionate about people celebrating their wedding their way. I believe that little things make a big difference; as your celebrant you can be assured your ceremony will represent your style and tastes and be a delight for those who come to witness you say “I do”.</p>
                    <p>As an avid traveller I especially love a destination wedding and never tire of discovering new sites for ceremonies.</p>
                    <p>With over 20 years experience in planning, coordinating and facilitating events, together with a love of public speaking and flair for speech writing, I can’t wait to be a part of your special day.</p>
                  </span>
                  <MDBBtn outline color="info" href="/about/">Learn more about Amanda...</MDBBtn>
                </MDBContainer>
              </MDBCol>
            </MDBRow>
          </section>
          <section id="shy" className="text-center">
            <MDBRow>
              <MDBCol size="4">
                <img src={wd_badge} className="img-fluid" alt="" />
              </MDBCol>
              <MDBCol size="8">
                <h4>Dont Be Shy</h4>
                <p>Contact me to arrange a time to meet in person or chat over phone, email or Skype so I can hear your story and your vision and you can determine if I am the right person to marry you.</p>
                <MDBBtn outline color="info" href="/contact/">Get In Touch</MDBBtn>
              </MDBCol>
            </MDBRow>
          </section>
        </main>
        </Layout>
      </>
    );
  }
}

export default App;
